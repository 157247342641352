/**
 * SPDX-FileCopyrightText: (c) 2000 Liferay, Inc. https://liferay.com
 * SPDX-License-Identifier: LGPL-2.1-or-later OR LicenseRef-Liferay-DXP-EULA-2.0.0-2023-06
 */

export default {
	BAD_REQUEST: 400,
	INTERNAL_SERVER_ERROR: 500,
	OK: 200,
	SC_DUPLICATE_FILE_EXCEPTION: 490,
	SC_FILE_ANTIVIRUS_EXCEPTION: 494,
	SC_FILE_CUSTOM_EXCEPTION: 499,
	SC_FILE_EXTENSION_EXCEPTION: 491,
	SC_FILE_MIME_TYPE_EXCEPTION: 496,
	SC_FILE_NAME_EXCEPTION: 492,
	SC_FILE_SIZE_EXCEPTION: 493,
	SC_UPLOAD_REQUEST_SIZE_EXCEPTION: 495,
};
